import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout/Layout";
import { GameDescription } from "../../components/sections/games/GameDescription";
import { Container } from "../../components/layout/Container";
import { Navigation } from "../../components/navigation/Navigation";
import { HeaderSize } from "../../components/layout/ParallaxHeader";
import { ImageHeader } from "../../components/layout/ImageHeader";
import Img, { FluidObject } from "gatsby-image";
import { GalleryOrientation, GameGallery } from "../../components/sections/games/GameGallery";
import { ContainerColour } from "../../components/layout/DiagonalContainer";
import { Button, ButtonColour, ButtonSize } from "../../components/layout/Button";
import { hyperTapPageData } from "../../content/pageData/games/HyperTapPageData";

type PageData = {
    data: {
        site: {
            siteMetadata: {
                title: string;
                description: string;
            }
        }
        rrHeaderMobile: {
            childImageSharp: any;
        }
        rrHeaderXL: {
            childImageSharp: any;
        }
        rrHeader: {
            childImageSharp: any;
        }
        logo: {
            childImageSharp: any;
        }
        description: {
            childImageSharp: any;
        }
        one: {
            childImageSharp: any;
        }
        two: {
            childImageSharp: any;
        }
        three: {
            childImageSharp: any;
        }
        four: {
            childImageSharp: any;
        }
        guy: {
            childImageSharp: any;
        }
    }
}

export default ({ data }: PageData) => {
    const headerImageMobile = data.rrHeaderMobile.childImageSharp.fluid;
    const headerImageXL = data.rrHeaderXL.childImageSharp.fluid;
    const headerImage = data.rrHeader.childImageSharp.fluid;

    const headerSources = [
        headerImageMobile,
        {
            ...headerImageXL,
            media: `(min-width: 1100px)`,
        },
        {
            ...headerImage,
            media: `(min-width: 768px)`,
        }
    ]

    const images: FluidObject[] = [
        data.one.childImageSharp.fluid,
        data.two.childImageSharp.fluid,
        data.three.childImageSharp.fluid,
        data.four.childImageSharp.fluid,
    ];

    return (
        <Layout
            pageTitle={hyperTapPageData.metaPageTitle}
            keywords={hyperTapPageData.metaKeywords}
            description={hyperTapPageData.metaDescription}
            url={"work/hyper-tap"}
        >
            <Navigation />
            <ImageHeader size={HeaderSize.LARGE} imageSrc={headerSources} archived={true}>
                <div className="logo-container">
                    <div className="image-wrapper ht">
                        <Img fluid={data.logo.childImageSharp.fluid} alt={`${hyperTapPageData.pageTitle} logo`} />
                    </div>
                </div>
            </ImageHeader>
            <Container className="game-description-container">
                <GameDescription
                    gameTitle={"Hyper Tap"}
                    title={hyperTapPageData.descriptionTitle}
                    description={hyperTapPageData.description}
                    links={hyperTapPageData.links}
                    image={data.description.childImageSharp.fluid}
                    imageAlt={"Hyper Tap Woman"}
                />
            </Container>
            <GameGallery colour={ContainerColour.HT} images={images} orientation={GalleryOrientation.PORTRAIT}>
                <div className="ht-guy">
                    <Img fluid={data.guy.childImageSharp.fluid} alt="Hyper Tap Man" />
                </div>
            </GameGallery>
            <Container className="more-work">
                <Button buttonConfig={{
                    colour: ButtonColour.BLUE,
                    size: ButtonSize.LARGE,
                    ctaLabel: "More Work",
                    ctaLink: "/work",
                    id: "blueGradient",
                    ariaLabel: "View our other work",
                }}
                />
            </Container>
        </Layout>
    );
};


export const query = graphql`
    query {
        site {
            siteMetadata {
                title
                description
            }
        }
        rrHeaderMobile: file(relativePath: { eq: "assets/images/work/ht-header.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 768, maxHeight: 550, quality: 100, cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        rrHeaderXL: file(relativePath: { eq: "assets/images/work/ht-header.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1581, maxHeight: 700, quality: 100, cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        rrHeader: file(relativePath: { eq: "assets/images/work/ht-header.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        logo: file(relativePath: { eq: "assets/images/work/ht/logo.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        description: file(relativePath: { eq: "assets/images/work/ht/description.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        one: file(relativePath: { eq: "assets/images/work/ht/1.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 864, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        two: file(relativePath: { eq: "assets/images/work/ht/2.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 418, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        three: file(relativePath: { eq: "assets/images/work/ht/3.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 418, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        four: file(relativePath: { eq: "assets/images/work/ht/4.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 418, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        guy: file(relativePath: { eq: "assets/images/work/ht/guy.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`