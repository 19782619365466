import React from "react";
import { GamePageData } from "./RecipeRescuePageData";

export const hyperTapPageData: GamePageData = {
    metaPageTitle: "Hyper Tap Hyper Casual Mobile Game - iDream Interactive",
    metaKeywords: "hypertap, what is hyper tap, what is hyper tap tetris",
    metaDescription: "Dodge the Neo Barriers! Learn more about our first hyper casual mobile game available on iOS and Android.",
    pageTitle: "Hyper Tap",
    descriptionTitle: "Dodge the Neo Barriers!",
    description: <>
        <p>This awesome “twitch” reflex game will have you gripping your seat. Dodge the on-coming Neo Barriers with increasing speed, forcing you to react at the last microsecond. How fast can you go? How high can you get your score?</p>
        <p>Tap now and find out!</p>
        <p>
            Easy to pick up and play - hyper difficult to master.
            Track your score with Game Center - Compete with your friends, and the world.
            Endless edge of your seat, arcade tap-action!
        </p>
    </>,
    links: []
}
